import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import ContentContainer from "../ContentContainer/contentcontainer"

const Container = styled.div`
    width: 100%;
    margin: auto;
    background-color: white;
    h2{
        font-family: bookmania;
        font-weight: 600;
        font-size: 2em;
        letter-spacing: 1px;
    }
    .title{
        width: 20%;
        display: inline-block;
        vertical-align: top;
        color: black;
    }
    .desc{
        width: 48%;
        display: inline-block;
    }
    .desc p{
        font-family: open sans, geneva;
        font-weight: 100;
        font-size: 1.2em;
        color: black;
        margin-bottom: 10px;
    }
    .photo{
        width: 25%;
        display: inline-block;
        vertical-align: top;
        margin-left: 30px;
        text-align: center;
    }
    .img-container{
        width: 79%;
        display: inline-block;
    }
    @media(max-width: 1100px){
        .desc, .photo, .title{
            display: block;
            width: 100%;
            text-align: left;
            margin-left: 0px;
            margin-bottom: 10px;
        }
        .img-container{
            width: 100%;
        }
    }
`
const GalleryImage = styled(Img)`
    width: 47%;
    margin: 10px;
    display: inline-block;
    @media(max-width: 800px){
        display: inline-block;
        width: 100%;
        margin:0;
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0px;
        }
    }
`

function LotRenders({data}){
   
    return(
        <Container>
          <ContentContainer>
                <div className="title">
                    <h2>Renders</h2>
                </div>
                <div className="img-container">
                    {data.r_one &&
                        <GalleryImage fluid={data.r_one.childImageSharp.fluid} />
                    }
                    {data.r_two &&
                        <GalleryImage fluid={data.r_two.childImageSharp.fluid} />
                    }
                    {data.r_three &&
                        <GalleryImage fluid={data.r_three.childImageSharp.fluid} />
                    }
                    {data.r_four &&
                        <GalleryImage fluid={data.r_four.childImageSharp.fluid} />
                    }
                    {data.r_five &&
                        <GalleryImage fluid={data.r_five.childImageSharp.fluid} />
                    }
                    {data.r_six &&
                        <GalleryImage fluid={data.r_six.childImageSharp.fluid} />
                    }
                    {data.r_seven &&
                        <GalleryImage fluid={data.r_seven.childImageSharp.fluid} />
                    }
                    {data.r_eight &&
                        <GalleryImage fluid={data.r_eight.childImageSharp.fluid} />
                    }
                </div>
          </ContentContainer>
        </Container>
    )
}

export default LotRenders
