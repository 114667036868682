import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import { Link } from "gatsby"

import ContentContainer from "../ContentContainer/contentcontainer"

const Container = styled(BackgroundImage)`
    width: 100%;
    height: 600px;
    .text-section{
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        bottom: 0;
        width: 100%;
        
    }
    .inner-text{
        width: 92%;
        margin: auto;
        margin-top: 40px;
        padding-bottom: 40px;
    }
    h1{
        color: white;
        font-family: bookmania;
        font-weight: 600;
        font-size: 2.6em;
        letter-spacing: 1px;
    
    }
    .crumbs{
        color: gray;
        font-family: open sans;
        font-weight: 100;
        text-transform: uppercase;
        font-size: 0.8em;
        letter-spacing: 1px;
        margin-top: 10px;
    }
    a{
        color: #DB995A;
        margin-right: 5px;
    }
    .current{
        color: gray;
    }
    @media(max-width: 600px){
      height: 400px;
    }
    @media(max-width: 400px){
      
      h1{
        font-size: 2em;
      }

    }
`

function LotHeader(props){
    const data = useStaticQuery(graphql`
    query {
      headerBgImage: file(relativePath: { eq: "hills_header.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return(
        <Container fluid={props.bg}>
            <div className="text-section">
               <div className="inner-text">
                 <h1>{props.name}</h1>
                 <div className="crumbs">
                     <Link to="/">Home</Link> / 
                     <Link to="/lots"> Our Lots</Link> / 
                    <Link className="current" to="/lot-1"> {props.name}</Link>
                 </div>
               </div>
            </div>
        </Container>
    )
}

export default LotHeader
