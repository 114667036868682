import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { FaPhone } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { IoIosMenu } from 'react-icons/io'
import SideMenu from "../SideMenu/sidemenu"
import { useState } from 'react';
import ContactData from "../../Data/contact-info.json"

const Container = styled.div`
    .logo-container{
        display: inline-block;
        width: 100px
        border-right: solid 1px #D5D5D5;
        text-align:center;
    }
    .right-container{
        display: inline-block;
        width: auto;
        vertical-align: top;
        width: calc(100% - 250px);
        border-left: solid 1px #D5D5D5;
        padding-bottom: 30px;
    }
    .contacts{
        width: 100%;
        margin-top: 8px;
        padding-bottom: 4px;
        border-bottom: solid 1px #D5D5D5;
        font-family: open sans;
        padding-bottom: 10px;
        padding-left: 40px;
    }
    .creek{
        font-family: bookmania;
        font-weight: 600;
        font-size: 1.3em;
        width: 250px;
        line-height: 25px;
        padding-top: 30px;
    }
    .sub{
        font-size: 0.9em;
        font-family: open sans ,geneva;
        font-weight: 100;
        letter-spacing: 1px;
        padding-bottom: 30px;
    }
    span{
        color:#858585;
        font-size: 0.9em;
    }
    .contacts span:hover{
        color: #DB995A;
    }
    span:first-child{
        margin-right: 40px;
        margin-left: 20px;
    }
    .contacts svg{
        display: inline-block;
        color: #DB995A;
        margin-right: 10px;
    }
   
    .nav-links div{
        width: auto;
        display: inline-block;
        height: 35px;
        text-align: center;
    }
   
    .nav-links a{
        font-family: open sans, geneva;
        font-weight: 300;
        font-size: 1em;
        color: black;
        letter-spacing: 1px;
        position: relative;
        padding-top: 20px;
        top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
    }
    .nav-links span:hover{
        color: #DB995A;
        border-top: 2px solid #DB995A;
    }
    .active-nav{
        color: #DB995A;
        border-top: 2px solid #DB995A;
    }
    .active-nav div{
        color: #DB995A;
    }
    .mobile-n{
        display: none !important;
    }
    .nav-links a:first-child{
        margin-left: 40px;
    }
    .small{
        display: none;
    }
    @media(max-width: 570px){
        .big{
            display: none;
        }
        .mobile-n{
            display: block;
        }
        .small{
            display: block;
        }
        .logo-container-m{
            display: inline-block;
            text-align:center;
           margin-left: 10%;
        }
        .creek-m{
            font-family: bookmania;
            font-weight: 600;
            font-size: 1.2em;
            width: 250px;
            line-height: 25px;
            padding-top: 25px;
        }
        .sub-m{
            font-size: 0.9em;
            font-family: open sans,geneva;
            font-weight: 100;
            letter-spacing: 1px;
            padding-bottom: 30px;
        }
    }
`
const MailIcon = styled(AiOutlineMail)`
    font-size: 1.3em;
`

const HMenu = styled(IoIosMenu)`
    color: black !important;
    font-size: 2.2em;
    display: inline-block;
    vertical-align: baseline;
    margin-left: 20px;
    cursor: pointer;
    @media(max-width: 570){
        display: none !important;
    }
`

function AltHeader(){
    const [open, setOpen] = useState(false)

    return(
        <Container>
            <div className="logo-container big">
                <div className="creek">Creekside Oaks Estates</div>
                <div className="sub">ALHAMBRA VALLEY, CA</div>
            </div>
            <div className="right-container big">
                <div className="contacts">
                    <a href={"tel:"+ContactData.phone_number}><span><FaPhone />{ContactData.phone_number}</span></a>
                    <a href={"mailto:"+ContactData.email}><span><MailIcon />{ContactData.email}</span></a>
                </div>
                <div className="nav-links big">
                    <Link activeClassName="active-nav" to="/"><div>Home</div></Link>
                    <Link activeClassName="active-nav" to="/lots"><div>Our Properties</div></Link>
                    <Link activeClassName="active-nav" to="/contact"><div>Contact</div></Link>
                </div>
            </div>
            <SideMenu open={open} setOpen={setOpen} />
            <div className="small">
                <HMenu open={open} onClick={() => setOpen(!open)} />
                <div className="logo-container-m">
                    <div className="creek-m">Creekside Oaks Estates</div>
                    <div className="sub-m">ALHAMBRA VALLEY, CA</div>
                </div>
            </div>
            <hr />
        </Container>
    )
}

export default AltHeader
