import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import styled from "styled-components"
import { FiChevronsDown } from 'react-icons/fi'
import { IoIosMenu } from 'react-icons/io'

const Container = styled.div`
    width: 92%;
    max-width: 1600px;
    margin: auto;
    padding: 80px 0px;
    font-size: 1em;
    .sub {
        text-transform: uppercase;
        font-family: open sans, geneva;
        font-size: 1em;
        font-weight: 400;
        color: #DB995A;
        letter-spacing: 3px;
        margin-bottom: 20px;
        
    }
    .mt-20{
        margin-top: 40px;
    }
`

function ContentContainer(props){
   

    return(
        <Container>
          {props.children}
        </Container>
    )
}

export default ContentContainer
