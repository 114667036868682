import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import GoogleMapReact from 'google-map-react'

import ContentContainer from "../ContentContainer/contentcontainer"

const Container = styled.div`
    width: 100%;
    
    margin: auto;
    background-color: #F5F5F4;
    h2{
        font-family: bookmania;
        font-weight: 600;
        font-size: 2em;
        letter-spacing: 1px;
    }
    .title{
        width: 20%;
        display: inline-block;
        vertical-align: top;
        color: black;
    }
    .desc{
        width: 48%;
        display: inline-block;
    }
    .desc p{
        font-family: open sans, geneva;
        font-weight: 100;
        font-size: 1.2em;
        color: #5F5F5F;
        margin-bottom: 10px;
    }
    .photo{
        width: 25%;
        display: inline-block;
        vertical-align: top;
        margin-left: 30px;
        text-align: center;
    }
    .map-container{
        width: 79%;
        height: 500px;
        display: inline-block;
    }
    .g-map{
        width: 100%;
        height: 100%;
        border: 0;
    }
    @media(max-width: 1100px){
        .desc, .photo, .title{
            display: block;
            width: 100%;
            text-align: left;
            margin-left: 0px;
            margin-bottom: 10px;
        }
        .map-container{
            width: 100%;
        }
    }
`


function LotAreas(props){
    const defaultProps = {
        center: {
          lat: 59.95,
          lng: 30.33
        },
        zoom: 11
      }
    const AnyReactComponent = ({ text }) => <div>{text}</div>
    return(
        <Container>
          <ContentContainer>
                <div className="title">
                    <h2>Surrounding Areas</h2>
                </div>
                <div className="map-container">
                <iframe src={props.src} className="g-map" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
          </ContentContainer>
        </Container>
    )
}

export default LotAreas
