import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import ContentContainer from "../ContentContainer/contentcontainer"

const Container = styled.div`
    width: 100%;
    margin: auto;
    background-color: #DB995A;
    color: white;
    text-align: center;
    font-weight: 100;
    font-family: open sans, geneva;
    font-size: 1em;
    .content{
        padding: 20px 0px;
        width: 92%;
        margin: auto;
    }
`



function LotDisclaimer(){
    const data = useStaticQuery(graphql`
    query {
      headerBgImage: file(relativePath: { eq: "hills_header.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return(
        <Container>
          <div className="content">
              <p>Disclaimer: This is a disclaimer letting the potential buyer know that only the lot is for sale. Floorplans, renders and elevation maps are not included in the sale of the lot.</p>
            </div>
        </Container>
    )
}

export default LotDisclaimer
