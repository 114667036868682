import React from 'react';
import styled from "styled-components"
import { Link } from "gatsby"

const StyledMenu = styled.nav`
    display:flex;
    flex-direction: column;
    z-index: 5;
    background: white;
    height: 100vh;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    width: 300px;
    transform: translateX(-100%);
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    a{
        font-size: 1.6em;
        font-family: open sans, geneva; 
        font-weight: 100;
        transition: color 0.3s linear;
        margin: 10px 0px;
        display: block;
        
    }
    .active{
        border-left: solid 4px #DB995A;
        color: #DB995A;
    }
    a:hover{
        color: #DB995A;
    }
    .exit-container{
        margin-bottom: 40px;
    }
    li{
        text-align: right;
        list-style-type: none;
        cursor: pointer;
        width: 20px;
        float: right;
        margin-right: 20px;
        font-family: open sans, geneva;
        font-weight: 100;
        font-size: 2em;
    }   
    .creek-side{
      font-family: bookmania;
      font-weight: 600;
      font-size: 1.2em;
      margin-bottom: -3px;
    }
    .sub{
      font-family: open sans;
      font-weight: 300;
      font-size: 0.8em;
      margin-bottom: 40px;
      text-transform: uppercase;
    }
    .logo{
      width: 100%;
      text-align:center;
    }
`

const SideMenu = ({open, setOpen}) => {
  return (
    <StyledMenu open={open}>
      <div className="exit-container">
            <li open={open} onClick={() => setOpen(!open)}>X</li>
      </div>
      <div className="logo">
        <div className="creek-side">Creekside Oaks Estates</div>
        <div className="sub">Alhambra Valley, Ca</div>
      </div>
      <div className="links">
      <Link activeClassName="active" to="/">
        Home
      </Link>
      <Link activeClassName="active" to="/lots">
        Our Properties
        </Link>
        <Link activeClassName="active" to="/contact">
        Contact
        </Link>
      </div>
    </StyledMenu>
  )
}
export default SideMenu