import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"

import ContentContainer from "../ContentContainer/contentcontainer"
import ContactData from "../../Data/contact-info.json"

const Container = styled.div`
    background-color: #1C1C1C;  
    font-family: open sans, geneva;
    .top{
        text-align: center;
    }
    .bottom{
        background-color: #FFFFFF;

    }
    .box-container{
       margin-bottom: -40px;
    }
    .footer-box{
        text-align: center;
        width: 32%;
        display: inline-block;
        vertical-align: top;

    }
    .footer-box h3{
        font-family: bookmania;
        font-weight: 600;
        font-size: 2em;
        color: white;
        letter-spacing: 1px;
        
    }
    .phone{
        font-size: 1.2em;
        color: #858585;
        font-weight: 100;
        margin-bottom: 20px;
    }
    .phone a{
        color: white;
    }
    .map-link{
        color: #DB995A;
        text-transform: uppercase;
        font-size: 1.2em;
        font-weight: 100;
        letter-spacing: 2px;

    }
    .footer-links{
        display: inline-block;
        margin-left: 20px;
        font-weight: 100;
        vertical-align: baseline;
        margin-top: 10px;
    } 
    .footer-links a{
        margin: 0px 20px;
        letter-spacing: 1px;
    }
    .footer-links a:hover{
        color: #DB995A;
    }
    .creek{
        font-family: bookmania;
        font-weight: 600;
        font-size: 1.2em;
    }
    .creek-sub{
        font-weight: 100;
    }
    .logo{
        display: inline-block;
        float: right;
        text-align: center;
        margin-right: 40px;
        color: black;
    }
    .footer-nav{
        margin: 40px 0px;
        padding-top: 20px;
    }
    .footer-tag{
        text-align: center;
        font-weight: 100;
        letter-spacing: 1px;
        margin: 20px 0px;
    }
    @media(max-width: 600px){
        .footer-box{
            width: 100%;
            margin-bottom: 40px;
        }
        .box-container{
            &:first-child{
                margin-bottom: 0px;
            }
        }
    }
    @media(max-width: 540px){
        .footer-links{
            display: none;
        }
        .logo{
            float: none;
            width: 100%;
            margin-bottom: -20px;
            margin-right: 0px;
        }
        .footer-tag{
            width: 95%;
            margin: auto;
            margin-top: 10px;
            margin-botom: 105x;
        }
        .footer-nav{
            margin: 10px 0px;
            padding-top: 20px;
        }
    }
`

const ContactLink = styled(Link)`
    background-color: #DB995A;
    color: white;
    letter-spacing: 3px;
    font-family: open sans;
    font-size: 0.8em;
    font-weight: 100;
    text-transform: uppercase;
    padding: 10px 45px;
    
`

function Footer(){
    return(
        <Container>
            <ContentContainer>
                <div className="top">
                    <div className="box-container">
                        <div className="footer-box">
                            <h3>Call Us</h3>
                            <div className="phone">Phone: <a href={"tel:"+ContactData.phone_number}>{ContactData.phone_number}</a></div>
                        </div>
                        <div className="footer-box">
                            <h3>Our Location</h3>
                            <div className="phone">{ContactData.address_street} <br/> {ContactData.address_city}, {ContactData.address_state} {ContactData.address_zip}</div>
                            <a className="map-link" href="https://www.google.com/maps/place/2+Creekside+Oaks,+Martinez,+CA+94553/@37.9660688,-122.1310287,177m/data=!3m1!1e3!4m5!3m4!1s0x808564f86a7b01ad:0xc4e380f4489677ec!8m2!3d37.966662!4d-122.13057">Get Directions</a>
                        </div>
                        <div className="footer-box">
                            <h3>Email Us</h3>
                            <div className="phone">Email: <a href={"mailto:"+ContactData.email}>{ContactData.email}</a></div>
                        </div>
                    </div>
                </div>
            </ContentContainer>
                <div className="bottom">
                    <div className="footer-nav">
                    <div className="footer-links">
                        <Link to="">Home</Link>
                        <Link to="">Our Properties</Link>
                        <Link to="">Contact</Link>
                    </div>
                    <div className="logo">
                        <div className="creek">Creekside Oaks Estates</div>
                        <div className="creek-sub">Alhambra Valley, Ca</div>
                    </div>
                    </div>
                    <hr />
                    <div className="footer-tag">Copyright 2020 USGL Land. All Rights Reserved.</div>
                </div>
        </Container>
    )
}

export default Footer