import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import ContentContainer from "../ContentContainer/contentcontainer"

const Container = styled.div`
    width: 100%;
    margin: auto;
    background-color: #FFFFFF;
    h2{
        font-family: bookmania;
        font-weight: 600;
        font-size: 2em;
        letter-spacing: 1px;
    }
    .title{
        width: 20%;
        display: inline-block;
        vertical-align: top;
        color: black;
    }
    .desc{
        width: 48%;
        display: inline-block;
    }
    .desc p{
        font-family: open sans, geneva;
        font-weight: 100;
        font-size: 1.2em;
        color: black;
        margin-bottom: 10px;
    }
    .photo{
        width: 25%;
        display: inline-block;
        vertical-align: top;
        margin-left: 30px;
        text-align: center;
    }
    @media(max-width: 1100px){
        .desc, .photo, .title{
            display: block;
            width: 100%;
            text-align: left;
            margin-left: 0px;
            margin-bottom: 10px;
        }
    }
`

const DescPhoto = styled(Img)`
    width: 90%;
    height: 200px;
    margin: auto;
    @media(max-width: 1100px){
        display: none;
    }
`

function LotElevationMap(){
    const data = useStaticQuery(graphql`
    query {
      headerBgImage: file(relativePath: { eq: "hills_header.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return(
        <Container>
          <ContentContainer>
                <div className="title">
                    <h2>Elevation Map*</h2>
                </div>
                
          </ContentContainer>
        </Container>
    )
}

export default LotElevationMap
