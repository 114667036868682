import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import ContentContainer from "../ContentContainer/contentcontainer"

const Container = styled.div`
    width: 100%;
    margin: auto;
    background-color: #F5F5F4;
    h2{
        font-family: bookmania;
        font-weight: 600;
        font-size: 2em;
        letter-spacing: 1px;
    }
    .title{
        width: 20%;
        display: inline-block;
        vertical-align: top;
        color: black;
    }
    .video-container{
        width: 79%;
        display: inline-block;
    }
    .desc{
        width: 48%;
        display: inline-block;
    }
    .desc p{
        font-family: open sans, geneva;
        font-weight: 100;
        font-size: 1.2em;
        color: black;
        margin-bottom: 10px;
    }
    .photo{
        width: 25%;
        display: inline-block;
        vertical-align: top;
        margin-left: 30px;
        text-align: center;
    }
    @media(max-width: 1110px){
        .desc, .photo, .title{
            display: block;
            width: 100%;
            text-align: left;
            margin-left: 0px;
            margin-bottom: 10px;
        }
        .video-container{
            width: 100%;
        }
    }
    .vimeo{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%
    }
    .vimeo-container{
        padding:56.25% 0 0 0;position:relative;
    }
`

const DescPhoto = styled(Img)`
    width: 90%;
    height: 200px;
    margin: auto;
    @media(max-width: 1100px){
        display: none;
    }
`

function LotVideo(props){
    const data = useStaticQuery(graphql`
    query {
      headerBgImage: file(relativePath: { eq: "hills_header.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return(
        <Container>
          <ContentContainer>
                <div className="title">
                    <h2>Video</h2>
                </div>
                <div className="video-container">
                    <div className="vimeo-container" >
                        <iframe src={"https://player.vimeo.com/video/" + props.videoId + "?color=ff9800&title=0"} className="vimeo"  frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
          </ContentContainer>
        </Container>
    )
}

export default LotVideo
