/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import AltHeader from "../components/AltHeader/altheader"
import "./layout.css"

const AltLayout = ({ children }) => {

  return (
    <>
      <AltHeader  />
      <div
        style={{
          margin: `0 auto`,
         
        }}
      >
        <main>{children}</main>
        <footer>
         
        </footer>
      </div>
    </>
  )
}



export default AltLayout
